import {ThemeProvider} from "styled-components"
import * as React from "react"
import {useStaticQuery, graphql} from "gatsby"
import styled from 'styled-components';
import theme from "../styles/theme";
import GlobalStyles from "../styles/GlobalStyles";

import "../static/fonts/fonts.css"


const Page = styled.div`
  flex: 1;
`

const Layout = ({children}: {children: React.ReactNode}) => (
    <ThemeProvider theme={theme}>
        <>
            <GlobalStyles/>
            {children}
        </>
    </ThemeProvider>
)

export default Layout
